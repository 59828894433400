<template>
  <v-container fluid>
    <v-row v-if="!isSubagent" justify="start">
      <v-col cols="12" md="3">
        <v-select :disabled="isRailway" label="Статус заказа" :items="statuses" v-model="currentOrderStatus"></v-select>
      </v-col>
      <v-col cols="12" md="3">
      </v-col>
      <v-col cols="12" md="3">
        <v-text-field v-model="currentText" label="Номер билета, имя, email или телефон(без +7) пассажира"></v-text-field>
      </v-col>
      <v-col cols="12" md="3">
        <v-row>
          <v-col cols="6">
            <v-text-field outlined label="Цена от" v-model="currentPrice[0]"></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field outlined label="Цена до" v-model="currentPrice[1]"></v-text-field>
          </v-col>
        </v-row>
      </v-col>
      <v-row justify="end" class="mb-2">
        <v-col cols="12" md="2">
          <v-btn block dark color="orange" elevation="0" @click="search()">
            Поиск
          </v-btn>
        </v-col>
      </v-row>
    </v-row>

    <v-row v-if="loading" no-gutters>
      <v-col>
        <overlay-loader :loading="loading"></overlay-loader>
      </v-col>
    </v-row>


    <div v-else-if="items.length" class="mt-sm-3">
      <v-row v-for="(item, i) in items" :key="i">
        <v-col>
          <solded-ticket :item="item" :colorIndex="i % 2" class="mt-2" />
        </v-col>
      </v-row>
      <v-row justify="center" class="mt-5">
        <v-pagination dark color="orange" v-model="currentPage" :length="pageCount" total-visible="25"></v-pagination>
      </v-row>


    </div>
  </v-container>
</template>

<script>
import LoadingText from "@/components/UI/LoadingText.vue";
import SoldedTicket from "@/components/SoldedTicketsPage/SoldedTicketItem.vue";
import ErrorModal from "@/components/UI/ErrorModal";
import OverlayLoader from "@/components/UI/OverlayLoader";
import ApiCalls from "@/mixins/booking/ApiCalls";
import DateFormat from "@/mixins/DateFormat";
import RangeSlideFilter from "@/components/UI/RangeSlideFilter.vue";
import { useRoute } from 'vue-router';
import { providers, railwayStatuses, statuses } from "@/services/constants";

export default {
  name: "SoldedTickets",
  components: {
    OverlayLoader,
    ErrorModal,
    SoldedTicket,
    LoadingText,
    RangeSlideFilter,
  },
  mixins: [DateFormat, ApiCalls],
  props: {
    text: {
      type: String,
      default: "",
    },
    minPrice: {
      type: String,
      default: "",
    },
    maxPrice: {
      type: String,
      default: "",
    },
    orderStatus: {
      type: String,
      default: "",
    },
    provider: {
      type: String,
      default: "",
    },
    page: {
      type: String,
      default: "1",
    },
    take: {
      type: String,
      default: "10",
    },
    type: {
      type: String,
      default: "avia",
    },
  },
  data() {
    return {
      providers,
      railwayStatuses,
      statuses,
      isRailway: false,
      currentText: this.text,
      currentPrice: [this.minPrice, this.maxPrice],
      currentOrderStatus: this.orderStatus !== "" ? Number(this.orderStatus) : "",
      currentProvider: this.provider,
      currentPage: Number(this.page),
      pageCount: 1,
      pageCountRailway: 1,
      loading: true,
      items: [],
      trainItems: [],
      /*ticketitem: [],*/
      bookingData: [],
    };
  },
  async mounted() {
    await this.loadData(this.type);
  },
  watch: {
    currentPage: 'search',
  },
  computed: {
    isSubagent() {
      return this.$store.getters.user.role == this.$enums.Roles.SubAgent;
    },
  },
  methods: {
    async handleClick(type) {
      this.currentText = '';
      this.currentPage = 1;
      this.isRailway = type === 'railway';
      await this.loadData(type);
      await this.$router.push({ path: '/solded-tickets', query: { type: type } });
    },
    async loadData(type) {
      this.loading = true;
      let endpoint;
      if (type === 'railway') {
        endpoint = this.$enums.Endpoints.RailwayOrder.GetAllRailwayOrders;
      } else if (type === 'avia') {
        endpoint = this.$enums.Endpoints.Data.Orders;
      } else {
        throw new Error(`Invalid type: ${type}`);
      }
      let resp = await this.$axios.get(
        `${endpoint}?accountId=${this.$store.state.user.id}&text=${this.currentText}&minPrice=${this.currentPrice[0]}&maxPrice=${this.currentPrice[1]}&orderStatus=${this.currentOrderStatus}&provider=${"Mixvel"}&page=${this.currentPage - 1}&take=${this.take}`
      );
      if (type === 'railway') {
        this.bookingData = resp.data.pageItems;
      } else {
        this.items = resp.data.pageItems;
      }
      this.pageCount = Math.ceil(resp.data.total / this.take);
      this.loading = false;
    },
    async search(page = 1) {
      await this.$router.push(
        `/solded-tickets?type=${this.type}&text=${this.currentText}&minPrice=${this.currentPrice[0]}&maxPrice=${this.currentPrice[1]}&orderStatus=${this.currentOrderStatus}&provider=${this.currentProvider}&page=${page}&take=${this.take}`
      );
      await this.loadData(this.type);
    },
  },
};

</script>
